import { Image, PriceLabel, SiteLink } from 'components/shared';
import { useCart } from 'hooks';
import { capitalize, formatPrice, getLinkHref } from 'lib/utils/helpers';
import { trackInWindow, trackEvents } from 'hooks/tracking/track';
import { useStoreContext } from 'contexts';
import toItem from 'hooks/tracking/to-item';

const LineItem = ({ lineItem, removeCopy, colorCopy, sizeCopy, isInCart = true }) => {
  const { quantity, merchandiseQuantity = quantity, merchandise } = lineItem;

  const {
    update: updateQuantity,
    remove: removeItem,
    add: addItem,
    cartGiftLoading,
    setCartGiftLoading,
  } = useCart();
  const { currency } = useStoreContext();

  const { waist, length, size } = merchandise.selectedOptions.reduce(
    (acc, option) => ({ ...acc, [option.name.toLowerCase()]: option.value }),
    {}
  );

  const thumbnailSizes = `(max-width: 767px) 100px,
  (min-width: 768px) 120px`;

  const color = merchandise?.product?.genericColorName;

  const waistLengthSize = waist && length ? `${waist} / ${length}` : null;
  const sizeValue = size || waistLengthSize;

  const isFreeDiscount = Boolean(lineItem?.attributes?.find(({ key }) => key === '_discount'));

  const { slug, productTitle = lineItem.title } = merchandise?.product;

  const freeGiftSuggestion = isFreeDiscount && !isInCart;

  const handleRemoveItems = lineItemId => {
    trackInWindow({
      event: trackEvents.removeFromCart,
      ecommerce: {
        currency,
        remove: {
          items: [toItem(merchandise.product, merchandise, { quantity: 1 })],
        },
      },
    });

    removeItem(lineItemId);
  };

  return (
    <div
      className={`flex items-center border-b-[1px] border-blue border-opacity-20 py-2 last:border-b-[0px] md:py-4 ${
        cartGiftLoading && freeGiftSuggestion && 'pointer-events-none cursor-default opacity-50'
      }`}
    >
      <div className={`${freeGiftSuggestion && 'pointer-events-none cursor-default'}`}>
        <SiteLink href={getLinkHref({ __typename: 'PageProduct', slug })}>
          {(merchandise?.product?.thumbnailImage || merchandise?.image) && (
            <Image
              src={merchandise?.product?.thumbnailImage || merchandise?.image}
              layout="fill"
              className="relative mr-[28px] h-[114px] w-[94px] min-w-[94px] md:h-[130px] md:w-[106px]"
              sizes={thumbnailSizes}
            />
          )}
        </SiteLink>
      </div>
      <div className="relative flex flex-1 justify-between pb-3">
        <div className="mt-[12px] w-full text-[12px] leading-[115%]">
          <div className="flex flex-wrap justify-between gap-2 pb-2  md:flex-nowrap md:gap-4">
            <div className={`${freeGiftSuggestion && 'pointer-events-none cursor-default'}`}>
              <SiteLink href={getLinkHref({ __typename: 'PageProduct', slug })}>
                <p className="font-nimbus-sans-extd-d text-[14px] font-bold uppercase md:text-[16px]">
                  {productTitle}
                </p>
              </SiteLink>
            </div>
            {merchandise?.priceV2 && (
              <p className="text-[12px]">
                {isFreeDiscount ? (
                  <>
                    <span className="mr-1 text-[#828282] line-through">
                      {formatPrice(
                        merchandise.priceV2.amount * merchandiseQuantity,
                        merchandise.priceV2.currencyCode ?? currency
                      )}
                    </span>
                    {isFreeDiscount &&
                    merchandise.priceV2.amount * merchandiseQuantity ===
                      Number(merchandise.priceV2.amount) ? (
                      <span className="font-bold text-red">Free</span>
                    ) : (
                      <span className="font-bold text-red">
                        {formatPrice(
                          merchandise?.priceV2?.amount * lineItem?.quantity,
                          merchandise.priceV2.currencyCode
                        )}
                      </span>
                    )}
                  </>
                ) : (
                  <PriceLabel
                    price={merchandise.priceV2.amount * merchandiseQuantity}
                    compareAtPrice={merchandise.compareAtPriceV2?.amount}
                    currencyCode={merchandise.priceV2.currencyCode}
                  />
                )}
              </p>
            )}
          </div>
          {color && (
            <p>
              {colorCopy}: {capitalize(color)}
            </p>
          )}
          {sizeValue && (
            <p>
              {sizeCopy}: {sizeValue}
            </p>
          )}
          {freeGiftSuggestion && (
            <>
              <div className="row my-3 flex h-5 md:my-2 md:h-4">
                <button
                  className="flex h-full items-center justify-center border border-black border-opacity-40 px-1 py-2 text-[12px] font-bold"
                  type="button"
                  onClick={() => {
                    addItem(merchandise.id);
                    setCartGiftLoading(true);
                  }}
                >
                  Add to cart
                </button>
              </div>
              {merchandiseQuantity > 1 && (
                <span className="my-3 flex w-5 items-center justify-center border border-black border-opacity-40 text-center text-[10px] font-bold md:my-3">
                  {merchandiseQuantity}
                </span>
              )}
            </>
          )}
          {!isFreeDiscount && isInCart && (
            <div className="row my-3 flex h-5 md:my-2 md:h-4">
              <button
                className="flex h-full w-10 items-center justify-center border border-r-0 border-black border-opacity-40 text-[12px] font-bold md:w-6"
                type="button"
                onClick={() => updateQuantity(lineItem.id, quantity - 1)}
                disabled={merchandiseQuantity === 1}
              >
                -
              </button>
              <div className="flex h-full w-5 items-center justify-center border border-black border-opacity-40 text-center text-[10px] font-bold">
                {merchandiseQuantity || quantity}
              </div>
              <button
                className="flex h-full w-10 items-center justify-center border border-l-0 border-black border-opacity-40 text-[12px]  font-bold md:w-6"
                type="button"
                onClick={() => updateQuantity(lineItem.id, quantity + 1)}
              >
                +
              </button>
            </div>
          )}
          {isInCart && (
            <button
              type="button"
              className={`text-[12px] underline opacity-[0.4] ${isFreeDiscount && 'mt-3 md:mt-2'}`}
              onClick={() => handleRemoveItems(lineItem.id)}
            >
              {removeCopy}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default LineItem;
